import { createRouter, createWebHistory } from "vue-router";
import { createRoutes } from "@/router/routes";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: createRoutes,
});

router.afterEach((to) => {
  document.title = to.meta.title || process.env.VUE_APP_NAME;
});

export default router;
