/* eslint-disable */
import {
  SET_GROUP,
  SET_SECTION,
  SET_PROJECT,
  SET_MEDIA,
  SET_EXHIBITION,
  SET_BIO,
  SET_VIDEO,
  SET_FULLSCREEN,
  SET_FULLSCREEN_ESCAPE,
  SET_DARK_THEME,
  SET_WINDOW_WIDTH,
  SET_TOUCH_SUPPORT,
  SET_MOUSE_SUPPORT,
  SUPPORT_TOUCH,
  SUPPORT_MOUSE,
  LOAD_PROJECT,
  SHOW_PROJECT,
  HIDE_PROJECT,
  CLEAR_PROJECT,
  GO_BACK,
  OPEN_FULLSCREEN,
  CLOSE_FULLSCREEN,
  OPEN_VIDEO_MODAL,
  CLOSE_VIDEO_MODAL,
  API_REQUEST,
  API_SUCCESS,
  API_ERROR,
} from "./handlers";

//import Vue from "vue"; // uncomment
//import Vuex from "vuex"; //vuncomment
import { createStore } from "vuex";
import router from "@/router";
import axios from "axios";
import { debounce } from "lodash";
import sitemap from "@/assets/sitemap.json";
//import main from "./modules/main";

//Vue.use(Vuex); //uncomment

export default createStore({
  state: {
    site: sitemap,
    group: null,
    section: null,
    project: null,
    media: null,
    video: null,
    slide: 0,
    exhibition: "",
    status: "",
    bio: "",
    darkTheme: false,
    showProject: false,
    windowWidth: 0,
    touchSupport: false,
    mouseSupport: false,
    fullscreen: null,
    fullscreenEscape: false,
    fullscreenSupport:
      document.fullscreenEnabled || 
      document.webkitFullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.msFullscreenEnabled,
  },
  getters: {
    getDirectoryById: (state) => (directory) => {
      return state.site[directory];
    },
    getExhibitsById: (state) => (directory, folder) => {
      return state.site[directory][folder];
    },
    isDesktop: (state) => (state.windowWidth > 768),
    isFullscreen: (state) => !!state.fullscreen,
    isProjectEntered: (state) => state.exhibition === "entered",
    isProjectShown: (state) => state.exhibition === "entering" || state.exhibition === "entered",
    isProjectOpen: (state) => !!state.project,
    isProjectLoading: (state) => state.status === "loading",
    isProjectReady: (state) => state.status === "success",
    isVideoOpen: (state) => !!state.video,
    transitionEvent: () => {
      var t;
      var el = document.createElement("fakeelement");
      var transitions = {
        transition: "transitionend",
        OTransition: "oTransitionEnd",
        MozTransition: "transitionend",
        WebkitTransition: "webkitTransitionEnd",
      };
      for (t in transitions) {
        if (el.style[t] !== undefined) {
          return transitions[t];
        }
      }
    },
	},
  mutations: {
    [SET_GROUP]: (state, val) => {
      state.group = val;
    },
    [SET_SECTION]: (state, val) => {
      state.section = val;
    },
    [SET_PROJECT]: (state, val) => {
      state.project = val;
    },
    [SET_MEDIA]: (state, val) => {
      state.media = val;
    },
    [SET_EXHIBITION]: (state, val) => {
      state.exhibition = val;
    },
    [SET_BIO]: (state, val) => {
      state.bio = val;
    },
    [SET_VIDEO]: (state, val) => {
      state.video = val;
    },
    [SET_FULLSCREEN]: (state, val) => {
      if (val) {
        state.fullscreen = val.nodeName === "HTML" ? "global" : "local";
      } else {
        state.fullscreen = undefined;
      }
    },
    [SET_FULLSCREEN_ESCAPE]: (state, val) => {
      state.fullscreenEscape = val;
    },
    [SET_DARK_THEME]: (state, val) => {
      state.darkTheme = val;
    },
    [SET_WINDOW_WIDTH]: (state, val) => {
      state.windowWidth = val;
    },
    [SET_TOUCH_SUPPORT]: (state) => {
      state.touchSupport = true;
    },
    [SET_MOUSE_SUPPORT]: (state) => {
      state.mouseSupport = true;
    },
    [SHOW_PROJECT]: (state) => {
      state.showProject = true;
    },
    [HIDE_PROJECT]: (state) => {
      state.showProject = false;
    },
    [API_REQUEST]: (state) => {
      state.status = "loading";
    },
    [API_SUCCESS]: (state) => {
      state.status = "success";
    },
    [API_ERROR]: (state, resp) => {
      state.status = "error: " + resp.data;
    },
  },
  actions: {
    [SUPPORT_TOUCH]: ({ commit, state }) => {
      if (!state.mouseSupport) commit(SET_TOUCH_SUPPORT);
    },
    [SUPPORT_MOUSE]: ({ commit, state }) => {
      if (!state.touchSupport) commit(SET_MOUSE_SUPPORT);
    },
    [CLEAR_PROJECT]: ({ commit, dispatch }) => {
      commit(SET_PROJECT, null);
      commit(SET_MEDIA, null);
    },
    [LOAD_PROJECT]: ({ commit }, id) => {
      commit(API_REQUEST);
      axios
        .get(
          `https://nickjeffrey.com/collect.php?id=${id}&query=list`
        )
        .then((response) => {
          commit(SET_MEDIA, response.data);
          commit(API_SUCCESS);
        });
    },
    [OPEN_FULLSCREEN]: ({ commit }, el) => {
      let fullscreen =
        el.requestFullscreen ||
        el.webkitRequestFullscreen ||
        el.mozRequestFullScreen ||
        el.msRequestFullscreen;
      fullscreen.call(el);
    },
    [CLOSE_FULLSCREEN]: () => {
      return new Promise((resolve, reject) => {
        let el = document;
        let fullscreen =
          el.exitFullscreen ||
          el.webkitExitFullscreen ||
          el.mozExitFullscreen ||
          el.msExitFullScreen;
        fullscreen.call(el);
        resolve();
      });
    },
    [OPEN_VIDEO_MODAL]: ({ commit }, id) => {
      commit(SET_VIDEO, id);
    },
    [CLOSE_VIDEO_MODAL]: ({ commit }) => {
      commit(SET_VIDEO, null);
      commit(SET_FULLSCREEN_ESCAPE, false);
    },
    [GO_BACK]: () => {
      setTimeout(() => {
        router.back();
      }, 100);
    },
  },
  plugins: [
	  store => {
	  	const resize = () => {
			  const w =
        	window.innerWidth ||
        	document.documentElement.clientWidth ||
        	document.body.clientWidth;
        const h =
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight;
        document.documentElement.style.setProperty('--vh', `${h * 0.01}px`);
        store.commit(SET_WINDOW_WIDTH, w);
			};

      const setFullscreen = () => {
        store.commit(
          SET_FULLSCREEN,
          document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msFullscreenElement
        );
      };

      if (store.state.fullscreenSupport) {
        document.addEventListener("fullscreenchange", setFullscreen);
        document.addEventListener("webkitfullscreenchange", setFullscreen);
        document.addEventListener("mozfullscreenchange", setFullscreen);
        document.addEventListener("MSFullscreenChange", setFullscreen);
      }
      //window.addEventListener("resize", debounce(resize, 250));
      window.addEventListener("resize", resize);
      resize();
	  }
	]
});
