export const SET_GROUP = "SET_GROUP";
export const SET_SECTION = "SET_SECTION";
export const SET_PROJECT = "SET_PROJECT";
export const SET_MEDIA = "SET_MEDIA";
export const SET_EXHIBITION = "SET_EXHIBITION";
export const SET_BIO = "SET_BIO";
export const SET_VIDEO = "SET_VIDEO";
export const SET_FULLSCREEN = "SET_FULLSCREEN";
export const SET_FULLSCREEN_ESCAPE = "SET_FULLSCREEN_ESCAPE";
export const SET_DARK_THEME = "SET_DARK_THEME";
export const SET_WINDOW_WIDTH = "SET_WINDOW_WIDTH";
export const SET_TOUCH_SUPPORT = "SET_TOUCH_SUPPORT";
export const SET_MOUSE_SUPPORT = "SET_MOUSE_SUPPORT";
export const SUPPORT_TOUCH = "SUPPORT_TOUCH";
export const SUPPORT_MOUSE = "SUPPORT_MOUSE";
export const LOAD_PROJECT = "LOAD_PROJECT";
export const SHOW_PROJECT = "SHOW_PROJECT";
export const HIDE_PROJECT = "HIDE_PROJECT";
export const CLEAR_PROJECT = "CLEAR_PROJECT";
export const GO_BACK = "GO_BACK";
export const OPEN_FULLSCREEN = "OPEN_FULLSCREEN";
export const CLOSE_FULLSCREEN = "CLOSE_FULLSCREEN";
export const OPEN_VIDEO_MODAL = "OPEN_VIDEO_MODAL";
export const CLOSE_VIDEO_MODAL = "CLOSE_VIDEO_MODAL";
export const API_REQUEST = "API_REQUEST";
export const API_SUCCESS = "API_SUCCESS";
export const API_ERROR = "API_ERROR";
