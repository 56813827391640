import store from "@/store";
import { SET_GROUP, SET_SECTION, SET_PROJECT } from "@/store/handlers";
import { capitalize } from "@/utils/helpers";

export let createRoutes = (() => {
  const baseName = process.env.VUE_APP_NAME;

  const formalGroup = {
    works: "Selected Works",
    misc: "Miscellaneous",
    about: "About",
  };

  const getTitle = function () {
    let args = Array.prototype.slice.call(arguments);
    return args.join(" | ") + " — " + baseName;
  };

  const setLocation = (to, from, next) => {
    store.commit(SET_GROUP, to.matched[0].meta.group);
    store.commit(SET_SECTION, to.matched[0].meta.section || null);
    next();
  };

  let routes = [
    {
      path: "/",
      redirect: {
        name: "news",
      },
    },
    {
      path: "/news",
      name: "news",
      meta: {
        title: getTitle("News"),
        group: "news",
      },
      component: () => import("@/views/Portfolio.vue"),
      pathToRegexpOptions: { strict: true },
      beforeEnter: setLocation,
    },
    {
      path: "/works",
      name: "works",
      meta: {
        title: getTitle(formalGroup.works),
        group: "works",
      },
      component: () => import("@/views/Portfolio.vue"),
      pathToRegexpOptions: { strict: true },
      beforeEnter: setLocation,
    },
    {
      path: "/misc",
      name: "misc",
      meta: {
        title: getTitle(formalGroup.misc),
        group: "misc",
      },
      component: () => import("@/views/Portfolio.vue"),
      pathToRegexpOptions: { strict: true },
      beforeEnter: setLocation,
    },
    {
      path: "/about",
      name: "about",
      meta: {
        title: getTitle("About"),
        group: "about",
      },
      component: () => import("@/views/Portfolio.vue"),
      pathToRegexpOptions: { strict: true },
      beforeEnter: setLocation,
    },
  ];

  for (const group in store.state.site) {
    let sections = store.state.site[group];
    let titleGroup = formalGroup[group];
    for (const section in sections) {
      let titleSection = capitalize(section);
      let children = [];
      let projects = sections[section];
      for (const project in projects) {
        let componentName = projects[project].view;
        let child = {
          path: project,
          name: [group, section, project].join("."),
          props: {
            id: project,
          },
          meta: {
            title: getTitle(projects[project].title, titleSection, titleGroup),
            project: projects[project],
          },
          component: () => import(`@/components/${componentName}.vue`),
          pathToRegexpOptions: { strict: true },
          beforeEnter: (to, from, next) => {
            store.commit(SET_PROJECT, to.meta.project);
            next();
          },
        };
        children.push(child);
      }
      let route = {
        path: `/${group}/${section}`,
        name: [group, section].join("."),
        meta: {
          title: getTitle(titleSection, titleGroup),
          group,
          section,
        },
        component: () => import("@/views/Portfolio.vue"),
        pathToRegexpOptions: { strict: true },
        beforeEnter: setLocation,
        children: children,
      };
      routes.push(route);
    }
  }
  routes.push({
    path: "/:pathMatch(.*)*",
    name: "404",
    meta: {
      title: getTitle("404 - Page Not Found"),
    },
    component: () => import("@/views/NotFound.vue"),
  });

  return routes;
})();
