import { createApp } from "vue";
import App from "./App.vue";
import VuexRouterSync from "vuex-router-sync";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
import router from "./router";
import store from "./store";

VuexRouterSync.sync(store, router);

createApp(App)
  .use(store)
  .use(router)
  .use(VuePlyr, {
    plyr: {},
  })
  .mount("#app");
