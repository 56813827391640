<template>
  <div
    id="base"
    :class="{
      'dark-theme': darkTheme,
      fullscreen: isFullscreen,
      fs: fullscreen == 'local',
    }"
  >
    <router-view />
  </div>
</template>

<script>
import { SUPPORT_TOUCH, SUPPORT_MOUSE } from "@/store/handlers";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapState(["darkTheme", "exhibition", "fullscreen"]),
    ...mapGetters(["isFullscreen"]),
  },
  methods: {
    ...mapActions({
      touchEvent: SUPPORT_TOUCH,
      mouseEvent: SUPPORT_MOUSE,
    }),
  },
  mounted() {
    this.$el.addEventListener("touchstart", this.touchEvent, { once: true });
    this.$el.addEventListener("mousemove", this.mouseEvent, { once: true });
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap");
@import "~@/assets/styles.css";
</style>
